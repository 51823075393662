import styled from 'utils/emotion';
import { toPx } from 'utils/styles';

export const StripeStyled = styled('div')(
  {
    position: 'relative',
  },
  (props) => ({
    height: props.theme.space,
    margin: `${toPx(props.theme.spaceHalf)} 0`,
    '&:before': {
      content: '""',
      position: 'absolute',
      left:
        props.align === 'left' ? 0 : props.align === 'center' ? '50%' : null,
      right: props.align === 'right' && 0,
      top: '50%',
      backgroundColor: props.color || props.theme.colorTertiary,
      width: props.stripeWidth || props.theme.spaceQuad,
      height: 1,
      transform: props.align === 'center' && 'translateX(-50%)',
    },
  }),
);
