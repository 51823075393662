import posed from 'react-pose';
import styled from 'utils/emotion';
import { easings } from 'components/common/Layout/variables';

export const PosedTarget = styled('div')({});

export const FadeInEaseOutQuad = posed.div({
  hidden: {
    opacity: 0,
  },
  shown: {
    opacity: 1,
    transition: {
      duration: 800,
      ease: easings.easeOutQuad,
    },
  },
});

export const SlideInPosed = posed.div({
  hidden: {},
  shown: {
    delayChildren: 175,
    staggerChildren: 175,
  },
});

export const SlideInChildPosed = posed.div({
  hidden: {
    y: 50,
    opacity: 0,
  },
  shown: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 500,
      ease: easings.easeOutQuad,
    },
  },
});

export const SlideInSlowPosed = posed.div({
  hidden: {},
  shown: {
    delayChildren: 500,
    staggerChildren: 300,
  },
});

export const SlideInSlowChildPosed = posed.div({
  hidden: {
    y: 50,
    opacity: 0,
  },
  shown: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 900,
      ease: easings.easeOutQuad,
    },
  },
});

/**
 * Hotfix for staggering children not working in IE 11
 *
 * see:
 * https://github.com/klaasman/react-pose-ie11-bug#screen-captures
 * https://github.com/Financial-Times/polyfill-library/issues/120#issuecomment-384561191
 * https://github.com/Popmotion/popmotion/pull/596
 *
 * This sets all children, that never receive a pose update to the shown pose.
 * Setting the initalPose is sufficient, since the pose-children never receive
 * the correct pose in the first place.
 */

const arrayFromAndSetBugIsPresent = Array.from(new Set('q')).length !== 1;

if (arrayFromAndSetBugIsPresent) {
  SlideInChildPosed.defaultProps = {
    ...SlideInChildPosed.defaultProps,
    initialPose: 'shown',
  };

  SlideInSlowChildPosed.defaultProps = {
    ...SlideInSlowChildPosed.defaultProps,
    initialPose: 'shown',
  };
}
