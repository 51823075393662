import React from 'react';
import PropTypes from 'prop-types';

import { StripeStyled } from './StripeStyled';

export default function Stripe({ className, stripeWidth, align, color }) {
  return (
    <StripeStyled
      className={className}
      stripeWidth={stripeWidth}
      align={align}
      color={color}
    />
  );
}

Stripe.propTypes = {
  className: PropTypes.string,
  stripeWidth: PropTypes.number,
  align: PropTypes.string,
  color: PropTypes.string,
};

Stripe.defaultProps = {
  className: '',
  stripeWidth: 64,
  align: 'left',
  color: null,
};
