import React, { isValidElement, useState } from 'react';
import PropTypes from 'prop-types';

import { parseAsContent } from 'utils/parser';

import Link from 'components/common/Link';
import { SlideInPosed, SlideInChildPosed } from 'components/common/Posed';

import {
  TextTeaserStyled,
  TextTeaserContentTextStyled,
  TextTeaserTaglineStyled,
  TextTeaserHeadlineLargeStyled,
  TextTeaserHeadlineNormalStyled,
  TextTeaserTextStyled,
  TextTeaserTextMoreStyled,
  TextTeaserButtonReadmoreStyled,
  TextTeaserStripeStyled,
  TextTeaserButtonStyled,
  TextTeaserButtonWrapStyled,
  BadgeImg,
} from './TextTeaserStyled';

export default function TextTeaser({
  teaserStyledAlign,
  tagline,
  taglineElement,
  headline,
  headlineElement,
  badges,
  noGold,
  isHeadlineLarge,
  text,
  textElement,
  textMore,
  textMoreLabel,
  textMoreElement,
  align,
  hasStripe,
  buttonPrimaryLink,
  buttonPrimaryText,
  buttonPrimaryTarget,
  buttonPrimaryTheme,
  buttonPrimaryOnClick,
  buttonSecondaryLink,
  buttonSecondaryText,
  buttonSecondaryTarget,
  buttonSecondaryTheme,
  buttonSecondaryOnClick,
  className,
  hasScrolledIntoView,
  textMaxWidth,
  innerRef,
  flickityRef,
}) {
  const TextTeaserHeadlineComponentStyled = isHeadlineLarge
    ? TextTeaserHeadlineLargeStyled
    : TextTeaserHeadlineNormalStyled;

  const [textMoreState, setTextMoreState] = useState({ display: false });

  function displayToggle() {
    setTextMoreState({ display: !textMoreState.display });
    setTimeout(() => {
      flickityRef?.current?.resize();
    }, 300); // Transition timing ~DH
  }

  console.log(buttonPrimaryLink, buttonPrimaryTarget)

  return (
    <SlideInPosed pose={hasScrolledIntoView ? 'shown' : 'hidden'}>
      <TextTeaserStyled
        className={className}
        teaserStyledAlign={teaserStyledAlign}
        ref={innerRef}
      >
        {(headline || text || tagline) && (
          <TextTeaserContentTextStyled align={align}>
            {tagline && (
              <SlideInChildPosed>
                <TextTeaserTaglineStyled element={taglineElement}>
                  {tagline}
                </TextTeaserTaglineStyled>
              </SlideInChildPosed>
            )}
            {headline && (
              <SlideInChildPosed>
                <TextTeaserHeadlineComponentStyled
                  omitTexture={noGold}
                  element={headlineElement}
                  hasBadges={badges && badges.length > 0}
                >
                  {/* Link headline if primary link is set. */}
                  {buttonPrimaryLink ? (
                    <Link to={buttonPrimaryLink} target={buttonPrimaryTarget}>
                      {parseAsContent(headline?.content || headline, {
                        onlyBasicTags: true,
                      })}
                    </Link>
                  ) : (
                    parseAsContent(headline?.content || headline, {
                      onlyBasicTags: true,
                    })
                  )}
                </TextTeaserHeadlineComponentStyled>
              </SlideInChildPosed>
            )}
            {badges && (
              <SlideInChildPosed>
                {badges.map(({ id, url, altText, title }) => (
                  <BadgeImg key={id} src={url} alt={altText || title} />
                ))}
              </SlideInChildPosed>
            )}
            {hasStripe && (
              <SlideInChildPosed>
                <TextTeaserStripeStyled
                  align={align}
                  color={noGold ? 'currentColor' : null}
                />
              </SlideInChildPosed>
            )}
            {text && (
              <SlideInChildPosed>
                <TextTeaserTextStyled
                  element={textElement}
                  textMaxWidth={textMaxWidth}
                >
                  {isValidElement(text)
                    ? text
                    : parseAsContent(text?.content || text, {
                        onlyBasicTags: true,
                      })}
                </TextTeaserTextStyled>
              </SlideInChildPosed>
            )}
            {textMore && (
              <SlideInChildPosed>
                <TextTeaserButtonReadmoreStyled
                  themeName="default"
                  onClick={() => displayToggle()}
                >
                  {textMoreLabel}
                </TextTeaserButtonReadmoreStyled>
                <TextTeaserTextMoreStyled
                  element={textMoreElement}
                  textMaxWidth={textMaxWidth}
                  displayToggle={textMoreState?.display}
                >
                  {parseAsContent(textMore?.content || textMore, {
                    onlyBasicTags: true,
                  })}
                </TextTeaserTextMoreStyled>
              </SlideInChildPosed>
            )}
          </TextTeaserContentTextStyled>
        )}

        {(buttonPrimaryText || buttonSecondaryText) && (
          <SlideInChildPosed>
            <TextTeaserButtonWrapStyled>
              {buttonPrimaryText && (
                <TextTeaserButtonStyled
                  to={buttonPrimaryLink}
                  target={buttonPrimaryTarget}
                  themeName={buttonPrimaryTheme}
                  onClick={buttonPrimaryOnClick}
                >
                  {buttonPrimaryText}
                </TextTeaserButtonStyled>
              )}
              {buttonSecondaryText && (
                <TextTeaserButtonStyled
                  to={buttonSecondaryLink}
                  target={buttonSecondaryTarget}
                  themeName={buttonSecondaryTheme}
                  onClick={buttonSecondaryOnClick}
                >
                  {buttonSecondaryText}
                </TextTeaserButtonStyled>
              )}
            </TextTeaserButtonWrapStyled>
          </SlideInChildPosed>
        )}
      </TextTeaserStyled>
    </SlideInPosed>
  );
}

TextTeaser.propTypes = {
  teaserStyledAlign: PropTypes.string,
  align: PropTypes.string,
  tagline: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  taglineElement: PropTypes.string,
  headline: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  headlineElement: PropTypes.string,
  noGold: PropTypes.bool,
  isHeadlineLarge: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textElement: PropTypes.string,
  textMore: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textMoreLabel: PropTypes.string,
  textMoreElement: PropTypes.string,
  hasStripe: PropTypes.bool,
  buttonPrimaryLink: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonPrimaryTarget: PropTypes.string,
  buttonPrimaryTheme: PropTypes.string,
  buttonPrimaryOnClick: PropTypes.func,
  buttonSecondaryLink: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonSecondaryTarget: PropTypes.string,
  buttonSecondaryTheme: PropTypes.string,
  buttonSecondaryOnClick: PropTypes.func,
  className: PropTypes.string,
  textMaxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasScrolledIntoView: PropTypes.bool,
};

TextTeaser.defaultProps = {
  teaserStyledAlign: 'left',
  align: 'left',
  tagline: '',
  taglineElement: 'p',
  headline: '',
  headlineElement: 'h2',
  noGold: false,
  isHeadlineLarge: false,
  text: '',
  textElement: 'div',
  textMore: null,
  textMoreLabel: 'weiterlesen',
  textMoreElement: 'div',
  hasStripe: true,
  buttonPrimaryLink: undefined,
  buttonPrimaryText: '',
  buttonPrimaryTarget: null,
  buttonPrimaryTheme: 'default',
  buttonPrimaryOnClick: null,
  buttonSecondaryLink: undefined,
  buttonSecondaryText: '',
  buttonSecondaryTarget: '_blank',
  buttonSecondaryTheme: 'default',
  buttonSecondaryOnClick: null,
  className: '',
  textMaxWidth: undefined,
  hasScrolledIntoView: true,
};
